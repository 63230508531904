var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "agreement" }, [
      _c("h2", [_vm._v("无敌鸭平台服务协议")]),
      _vm._v(" "),
      _c("h4", [_vm._v("特别提示：")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "在接受本协议之前，请您仔细阅读本协议的全部内容（特别是免除或者限制责任的条款、法律适用、争议解决条款和以粗体加下划线标注的内容）。如果您不同意本协议的任意内容，或者无法准确理解本协议任何条款的含义，请不要进行后续操作。如您对协议有任何疑问，可向无敌鸭平台客服咨询。当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与无敌鸭达成一致，成为无敌鸭平台“用户”。"
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "agreement-body" }, [
        _c("h4", [_vm._v("第1条 定义")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "1.1 无敌鸭平台：指由霍尔果斯数字森林信息技术有限公司运营的无敌鸭APP或与前述APP经营相同业务的移动客户端应用程序或网站（以下合称“无敌鸭”）。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "1.2 无敌鸭会员：指签署本协议并根据无敌鸭APP开通流程所展示的收费标准支付相应费用后获取的特殊资格（实际名称以开通流程及页面展示为准）。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "1.3 无敌鸭会员权益：指用户基于其无敌鸭会员资格所享有的特殊权益，包括特定商品的优惠权益、特定平台的会员资格、特定服务权益等，具体权益内容以无敌鸭APP页面的不定期更新为准。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "1.4 无敌鸭会员服务：指由无敌鸭及其合作的第三方向您提供的包括但不限于网络借贷信息中介推介业务、金融信息技术服务、消费、娱乐、资讯、交通出行、生活服务等各类服务。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "1.5 无敌鸭平台规则：包括无敌鸭平台已经发布及后续不时发布的全部规则、实施细则、流程说明、公告等。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "1.6 合作服务方：指通过无敌鸭平台为您提供包括但不限于网络借贷信息中介业务、金融信息服务、消费、娱乐、资讯、交通出行、生活服务等各类服务的相关公司。"
          ),
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("第2条 会员服务开通")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "2.1 当您按照页面提示填写信息、阅读并同意本协议、完成首次登陆程序并支付会员费后，您即获得无敌鸭平台账户并成为无敌鸭平台用户。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "2.2 您应确保在无敌鸭会员服务开通及使用过程中，始终具备完全民事权利能力和完全民事行为能力，并确保您具有签订和履行本协议项下权利义务之实际能力。若您不具备前述与您行为相适应的民事行为能力，则您应依照法律规定承担因此而导致的一切后果。为使您更好地使用无敌鸭平台的各项服务，保障您的账户安全，无敌鸭可要求您按照无敌鸭平台的规则及相关法律规定完成实名认证。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "2.3 您申请开通无敌鸭会员服务时，需根据页面提示支付相应的会员服务费用后方可完成开通。基于权益调整、市场与业务的发展，无敌鸭可能会调整会员服务开通所需会员服务费用，或向您提供与会员权益相关的增值服务，并可能收取增值服务的费用。会员服务费用调整自公布之日起生效，您于生效前已开通的无敌鸭会员服务不受影响，但该无敌鸭会员服务到期后若需续费开通，则需按照调整后已生效的费用标准支付。增值服务开始收费前无敌鸭将以适当的方式通知您具体标准，如您不同意调整后的费用，请您不要进行后续操作。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "2.4 您的账户由您自行设置并由您保管，无敌鸭任何时候均不会主动要求您提供您的手机验证码。因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开无敌鸭平台。账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，无敌鸭并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。除无敌鸭存在过错外，您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、申请借款、购买产品或服务等）负责。"
          ),
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("第3条 会员服务期限及具体权益")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "3.1 无敌鸭会员服务有效期为14天，自服务开通之日起计算。您在无敌鸭平台所享有的全部会员权益均需在会员服务有效期内完成。如果一项服务的服务期在您申请时已超过您剩余的会员服务有效期，则该项服务将不会向您提供，您可选择延长会员期限以获得该项服务权益。若您在有效期届满后希望继续享受无敌鸭会员服务的，则需续费或重新申请。如您在服务期尚未届满时选择续费的，则服务期限将顺延。续费或重新申请的会员期限均为14天。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "3.2 无敌鸭会员权益可能包括以下部分或全部类型（实际权益类型可能出现新增或减少）："
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(1) 商城服务：无敌鸭可能自行或通过合作服务方向您销售产品。如由无敌鸭向您提供服务的，使用服务过程中出现任何疑问或需求，您可直接与无敌鸭联系沟通。如由合作服务方向您提供服务的，您应按照您与合作服务方签订的具体服务协议向合作服务方支付产品的价款。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(2) 借款导流服务：无敌鸭平台为您提供借款导流推介服务，如您申请借款，将由具体的合作服务方进行信用审核并在通过审核后为您提供资金出借或借贷撮合服务。您理解，您的借款申请是否通过以合作服务方的审批意见为准，无敌鸭仅向您提供推介服务，不负责保证推介后的有效性及必然性，亦不承诺通过无敌鸭导流至合作服务方平台的用户都能通过借款申请并完成借款。借款相关的具体约定请您以与合作服务方签署的相关协议为准。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(3) 您知晓并同意，当您使用借款服务时，除了应向借款资金提供方偿付借款本金及利息外，还应就获取的借款资金向平台和/或合作服务方支付相关服务费用。具体费用标准以合作服务方页面公布或以您就具体服务签订的其他书面协议为准。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(4) 其他增值服务：无敌鸭还将向您提供包括但不限于消费、娱乐、资讯、交通出行、生活服务等各类服务。如上述任何服务系由合作服务方向您提供的，您应以您与合作服务方签订的具体协议中的约定为准。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "3.3 为持续改善您的无敌鸭会员体验，您理解无敌鸭会员权益所包含的会员权益可能出现新增或减少，新增的服务内容同样适合本条约定。同时，无敌鸭会对无敌鸭会员及/或其相关服务、权益、功能、界面等进行更新、修改，包括开发新功能／权益、删除旧功能／权益等。您可按照变更后无敌鸭会员的实际情况继续使用相应权益、功能或服务并适用本协议的相关约定。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "3.4 为更好地为您提供服务，无敌鸭将按照合作服务方为您提供产品和服务所必需的原则，向合作服务方提供您的有限相关信息。如果您不希望合作服务方获取您的信息，您可停止使用相关服务，无敌鸭将停止向合作服务方传递您的信息。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "3.5 如您通过无敌鸭向合作服务方购买产品或服务的，则您知晓并同意，合作服务方向您交付产品或服务并承担物流及售后保障，如您需要开具发票的，由合作服务方负责开具。无敌鸭平台仅提供居间服务，并非您所购买的产品或服务的提供方，不对产品或服务承担任何售后保障服务。如果您使用合作服务方的产品服务过程中出现任何疑问或需求，您可直接与合作服务方联系沟通。因您使用合作服务方提供的服务产生的争议，您可直接与合作服务方协商解决。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "3.6 您确认，您在无敌鸭APP上按无敌鸭APP服务流程所确认的交易状态将成为无敌鸭APP为您进行相关交易或操作（包括但不限于资金划转、代为支付或收取款项、订立合同等）的明确指令。您同意无敌鸭APP有权按相关指令依据本协议和/或有关文件和规则对相关事项进行处理。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "3.7 无敌鸭可通过APP推送、站内信、网站、电子邮件、手机短信等向您发送服务产品、重要提示、促销信息、订单/借款申请信息、服务协议、服务规则等信息。您如果不愿意接收此类信息，您可以联系无敌鸭客服进行退订。"
          ),
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("第4条 服务使用限制")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "4.1 您在使用无敌鸭平台服务时，必须遵守中华人民共和国相关法律法规的规定。您应自行承担违反上述法律法规和/或本协议所产生的责任和损失，并赔偿无敌鸭、无敌鸭的其他用户及相关第三方因您的违法行为、违约行为所产生的全部实际损失。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "4.2 无敌鸭会员服务及会员权益仅限您本人使用。开通无敌鸭会员服务或获取无敌鸭会员权益的账户作为您享受相应服务及权益的重要身份标识，用户名、密码和生物特征（如有）均由您自行设置并保管。为了您账户的安全性考虑，请您不要将自己的账户给予他人使用。同时从公平合理的角度，您应知晓所有通过该账户实现的操作无敌鸭只能默认视为您本人的行为，由此产生的后果由您与实际使用者自行承担。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "4.3 您确保您将合理使用无敌鸭会员服务，不利用无敌鸭会员服务进行盈利或非法获利，不以任何形式转让或转移您所享有的无敌鸭会员服务或无敌鸭会员权益，不以任何方式将无敌鸭会员服务或无敌鸭会员权益借给他人使用。若无敌鸭有合理理由怀疑您存在任何不当使用会员服务行为的，无敌鸭将取消您的无敌鸭会员资格、作废无敌鸭会员权益且您不应要求无敌鸭退还您所支付的会员服务费用。您应对您不当使用会员服务的行为及后果（包括损失）负责，您若给无敌鸭造成损失的，无敌鸭有权向您追偿。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "4.4 您保证您在获取和使用无敌鸭会员服务过程中应遵守诚实信用原则，若无敌鸭合理怀疑您存在以下任一情形的，您将可能面临无敌鸭会员服务开通申请被拒绝、已开通的无敌鸭会员服务被终止或中止、已绑定或获取的无敌鸭会员权益被全部／部分取消或暂停使用，且您不应要求退还已支付的会员服务费用（如有）："
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(1) 通过任何不正当手段或以违反诚实信用原则的方式开通无敌鸭会员服务的，如恶意绕过正常开通流程；"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(2) 同一用户注册、持有、使用或控制多个无敌鸭会员账号。其中，同一用户是指无敌鸭账户/支付宝账户注册信息、网络操作日志、交易订单信息等与用户身份和行为相关的信息，其中任意一项或数项存在相同、相似，亦或通过特定标记批量信息进行分析，其数项被存在相同、相似的；"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(3) 会员被认为存在违反无敌鸭会员服务中商品或服务提供方的相关协议或规则的行为，或已经被商品或服务提供方限制接受服务或购买商品的权利；"
          ),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("(4) 其他违反法律法规或诚实信用原则的行为。")]),
        _vm._v(" "),
        _c("p", [_vm._v("4.5 关于退款/退货的特别约定")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(1) 由于市场变化及各种以合理商业努力难以控制的因素的影响，无敌鸭无法保证您提交的订单信息中希望购买的产品都会有货；如您下单所购买的产品发生缺货，您有权取消订单；合作服务方或本平台亦有权取消订单，并为您（若已付款）办理退款。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(2) 您使用本协议约定的服务成功下单后，如发生退款/退货的，退款金额的确定及相关流程以平台规则为准及或合作服务方的商品售后服务政策及相关退货规则为准。"
          ),
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("第5条 会员服务终止")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "5.1 您应理解无敌鸭会员服务具备一定有效期，有效期届满后您未续费或重新开通的，无敌鸭会员服务终止，对应的无敌鸭会员权益失效。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "5.2 您应理解按本协议及相关规则使用无敌鸭会员服务、尊重无敌鸭会员服务使用限制以及按本协议约定履行义务是您使用无敌鸭会员服务的前提，如您违反本协议的相关约定，无敌鸭会视您的实际违约情况选择中止、终止或解除本协议。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "5.3 本协议的终止并不影响终止前您与无敌鸭基于本协议产生的权利义务。因您的原因导致无敌鸭遭受第三方索赔、行政部门处罚等，您应当赔偿无敌鸭因此产生的损失及（或）发生的费用，包括合理的律师费用、调查取证费用等。如因您的个人行为造成无敌鸭商誉损害的，无敌鸭有权要求您在公开媒体上发表声明以消除影响。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "5.4 您理解无敌鸭将尽最大努力确保本服务及其所涉及的技术及信息安全、有效、准确、可靠，但受限于现有技术及无敌鸭有限的服务能力，对于下述原因导致的合同履行障碍、瑕疵、延后或内容变更等情形，导致您直接或间接损失，您理解无敌鸭无法承担责任："
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(1) 因自然灾害、罢工、暴乱、战争、恐怖袭击、政府行为、司法行政命令等不可抗力因素；"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(2) 出于善意的维护和管理网络秩序的目的，无敌鸭设置的违规行为发现和排查机制误认为您存在违规行为，导致对您的服务中止，但根据您的请求或申请及时纠正或恢复的；"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("(3) 因电信部门技术调整或故障、通讯网络故障等公共服务因素；"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(4) 用户自身设备的软件、系统、硬件和通信线路出现故障，或用户通过非本协议项下约定的方式使用本服务的；"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(5) 无敌鸭已善意管理，但出现紧急设备/系统的故障/维护、网络信息与数据安全等情况。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "5.5 基于收益与赔偿相一致及公平合理的原则，如因无敌鸭原因造成无敌鸭会员服务不正常中断或服务不可用，您所可能获得的最高赔偿额不超过本协议项下无敌鸭已实际收取的相关服务费用总额。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "5.6 对于任何您自无敌鸭平台而获得的非无敌鸭自身的信息、内容、或者广告宣传等资讯，无敌鸭不负保证真实、准确和完整性的责任。您须自行甄别真伪和谨慎预防风险，否则，无论何种原因，无敌鸭不对任何非与本平台直接发生的交易和（或）行为承担任何直接、间接、附带或衍生的损失和责任。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "5.7 您理解并同意，因无敌鸭根据本协议并依法向第三方提供您的用户信息产生的任何后果和风险应由您自行承担，包括但不限于您的借款申请被拒绝、您无法获得更优惠的交易条件、您的相关信息被他人不法利用等。"
          ),
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("第6条 用户信息及隐私权保护")]),
        _vm._v(" "),
        _c("p", [_vm._v("6.1 用户信息的提供、搜集及核实")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(1) 您有义务在注册时提供自己的真实资料，并保证诸如电子邮件地址、联系电话、联系地址、邮政编码等内容的真实性、有效性、安全性和及时更新，以便无敌鸭APP为您提供服务并与您进行及时、有效的联系。您应完全独自承担因通过这些联系方式无法与您取得联系而导致的您在使用本服务过程中遭受的任何损失或增加任何费用等不利后果。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(2) 您同意无敌鸭APP通过公开及私人资料来源收集您的额外资料，以更好地了解无敌鸭APP用户，并为您度身订造无敌鸭APP服务、解决争议和确保在网站进行交易的安全性。无敌鸭APP仅收集无敌鸭APP认为就此目的及达成该目的所必须的关于您的个人资料，无敌鸭APP不会收集与为您提供服务目的无关的个人信息，您对此知悉并认可。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(3) 您同意无敌鸭APP可以自行或通过合作的第三方机构对您提交或无敌鸭APP搜集的用户信息（包括但不限于您的个人身份证信息等）进行收集、核实，并对获得的收集、核实结果根据本协议及有关文件进行查看、使用和留存等操作。您同意无敌鸭APP有权在法律法规允许范围内或主管机关的规定下按要求进行保存或处理您的全部个人信息，保存期限届满的，无敌鸭APP将主动或按照您的请求进行个人信息的删除、停止处理使用。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(4) 无敌鸭APP按照您在无敌鸭APP上的行为自动追踪关于您的某些资料。无敌鸭APP利用这些资料进行有关无敌鸭APP之用户的人口统计、兴趣及行为的内部研究，以更好地了解您以便向您和无敌鸭APP的其他用户提供更好的服务。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(5) 无敌鸭APP在无敌鸭APP的某些网页上使用诸如“Cookies”的资料收集装置。“Cookies”是设置在您的硬盘上的小档案，以协助无敌鸭APP为您提供度身订造的服务。无敌鸭APP亦提供某些只能通过使用“Cookies”才可得到的功能。无敌鸭APP还利用“Cookies”使您能够在某段期间内减少输入密码的次数。“Cookies”还可以协助无敌鸭APP提供专门针对您的兴趣而提供的资料。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(6) 如果您将个人通讯信息（例如：手机短信、电邮、微信等通讯APP或信件）授权或交付给无敌鸭APP，或如果其他用户或第三方向无敌鸭APP发出关于您在无敌鸭APP上的活动或登录事项的通讯信息，无敌鸭APP可以将这些资料收集在您的专门档案中或用于向您提供经您授权无敌鸭APP提供的相关服务。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("6.2 用户信息的使用和披露")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(1) 您同意无敌鸭APP可使用关于您的个人资料（包括但不限于无敌鸭APP持有的有关您的档案中的资料，及无敌鸭APP从您目前及以前在无敌鸭APP上的活动所获取的其他资料）以解决争议、对纠纷进行调停、确保在无敌鸭APP进行安全交易，并执行无敌鸭APP的服务协议及相关规则。为限制在网站上的欺诈、非法或其他刑事犯罪活动，使无敌鸭APP免受其害，您同意无敌鸭APP可通过人工或自动程序对您的个人资料进行评价。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(2) 您同意无敌鸭APP可以使用您的个人资料以改进无敌鸭APP的推广和促销工作、分析网站的使用率、改善无敌鸭APP的内容和产品推广形式，并使无敌鸭APP的网站内容、设计和服务更能符合用户的要求。这些使用能改善无敌鸭APP的网页，以调整无敌鸭APP的网页使其更能符合您的需求，从而使您在使用无敌鸭APP服务时得到更为顺利、有效、安全及度身订造的交易体验。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(3) 您同意无敌鸭APP自行或通过集成第三方推送服务功能模块为实现推送功能收集并使用您的资料与您联络并（在某些情况下）向您传递针对您的兴趣而提供的信息，例如：有针对性的广告投放、行政管理方面的通知、产品提供以及有关您使用无敌鸭APP的通讯。您接受本协议即视为您同意收取这些资料。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(4) 在您使用无敌鸭APP的账号存续期间，您即不可撤销的授权本公司根据实际需求，将您的包括但不限于真实姓名、联系方式、信用状况等必要的个人信息和交易信息一次或多次披露给与您交易的另一方或无敌鸭APP的合作机构查询或核实您的相关信息（如需），并同意业务相关第三方对您的相关信息进行收集并在与业务相关第三方之间共享等内容。本条所指的“业务相关第三方”仅限于无敌鸭APP为完成拟向您提供的服务而合作的机构。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(5) 无敌鸭APP有义务根据有关法律要求向司法机关和政府部门提供您的个人资料。在您未能按照与本协议、无敌鸭APP有关规则或者与无敌鸭APP其他用户签订的有关协议的约定履行自己应尽的义务时，无敌鸭APP有权根据自己的判断、有关协议和规则、国家生效裁决文书或者与该笔交易有关的其他用户的合理请求披露您的个人资料（包括但不限于在无敌鸭APP及互联网络上公布您的违法、违约行为，并将该内容记入任何与您相关的信用资料、档案或数据库），并且作为出借人的其他用户可以采取发布您的个人信息的方式追索债权或通过司法部门要求无敌鸭APP提供相关资料，无敌鸭APP对此不承担任何责任。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(6) 无敌鸭APP对于您提供的、自行收集到的、经认证的个人信息将按照本协议及有关规则予以保护、使用或者披露，如您发现无敌鸭APP违反法律、行政法规的规定或者双方的约定使用、披露您的个人信息，您可以要求无敌鸭APP删除。无敌鸭APP将采用行业标准惯例以保护您的个人资料，但鉴于技术限制，无敌鸭APP不能确保您的全部私人通讯及其他个人资料不会通过本协议中未列明的途径泄露出去。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(7) 如无敌鸭APP因本协议项下约定的情形与第三方分享您的信息，无敌鸭APP将会采用加密、匿名化处理等手段保障您的信息安全，无敌鸭APP将要求第三方按照本协议以及其他相关保密与安全措施及约定用途使用您的资料。如无敌鸭APP发生合并、收购、资产转让等交易导致向第三方分享您的个人信息时，无敌鸭APP将通过推送通知、公告等形式告知您相关情形，按照法律法规及不低于本协议所要求的标准继续保护或要求新的管理者继续保护您的个人信息。"
          ),
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("第7条 代扣授权")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "7.1 您授权无敌鸭根据本协议的约定，指示银行或其他第三方支付机构从7.2（1）条所述代扣账户主动扣收7.2（2）所述金额的款项。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("7.2 授权代扣账户、代扣金额及时间如下：")]),
        _vm._v(" "),
        _c("p", [_vm._v("(1) 代扣账户：您在无敌鸭绑定的所有银行账户。")]),
        _vm._v(" "),
        _c("p", [
          _vm._v("(2) 代扣金额：您开通无敌鸭会员之会员费及其他费用（如有）。"),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "(3) 代扣时间：在您申请开通会员后，无敌鸭根据您的授权或者您选择的服务进行不限次的扣款直至相关费用全部支付完成。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "7.3 您保证代扣账户信息真实、有效、准确及合法，因您提供的信息错误而引起的代扣失败等法律后果及损失由您自行承担。您应当按照应付/应还金额在上述账户内存入足够的资金；由于挂失、账户冻结、金额不足等原因造成扣款失败而导致您产生损失的，由您自行承担。您知晓并同意，受银行账户状态、银行、第三方支付机构及网络等原因所限，本条项下代扣事项可能会通过多次代扣、每次代扣部分金额方可完成，无敌鸭不对代扣资金到账时间做任何承诺。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "7.4 您知晓并同意，所扣款项将先用于支付会员费，再依次支付其他费用（如有）。无敌鸭有权根据平台的风险政策变更前述还款顺序，特别是在扣款金额小于您的应还金额的情况下，而无需另行通知您。"
          ),
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("第8条 其他")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "8.1 由于互联网高速发展，您与无敌鸭签署的本协议列明的条款并不能完整罗列和涵盖您与无敌鸭之间的所有权利与义务，现有的约定也不能完全保证符合未来发展的需求。无敌鸭可根据国家法律法规变化及维护服务秩序、保护用户权益的需要，不时修改本协议，变更后的协议（下称“变更事项”）将通过法定或约定程序通知您，并依法生效。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "8.2 本网站对本协议在法定的范围内保留一定的解释权。本协议及本网站有关页面的相关名词可互相引用参照，如有不同理解，则以本协议条款为准。此外，若本协议的部分条款被认定为无效或者无法实施时，本协议中的其他条款仍然有效。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "8.3 无敌鸭对无敌鸭APP及所有系统和网站的所有相关内容，包括但不限于各种文档资料、软件、商标、FLASH、设计、图案、音像、摄影、动画、美术等，依法拥有著作权、商标权、专利权、商业秘密以及其他相关的知识产权及知识产权的申请权。其它本协议中未经提及的权利亦由无敌鸭保留。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "8.4 本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "8.5 您因使用无敌鸭平台服务所产生及与无敌鸭平台服务有关的争议，由无敌鸭与您协商解决。若无法协商解决，则任何一方可将争议提交北京仲裁委员会，由该会按照届时有效的仲裁规则进行裁决。仲裁费用由败诉一方承担。"
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }