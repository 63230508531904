/**
 * @author zhenkunliu
 * @email zhenkunliu@yangqianguan.com
 * @date 2019-01-29 15:23:37.564
 * @desc generated by yqg-cli@0.2.4
 */

export default {
    name: 'Agreement',

    title: '无敌鸭协议',

    head() {
        return {};
    }
};
